
.lock img{
    width: 100%;
}
.login100-form-title{
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}
.lock{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}
