.notice{
    box-shadow: 0px 3px 12px 3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 3px 12px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 12px 3px rgba(0,0,0,0.75);
    padding:10px;
    height: 450px;
    overflow: scroll;
}
.notice ul{
    list-style: none;
    padding:0;
    margin:0;
}
.notice ul li:not(:first-child){
    padding:10px 0px;
    border-top:1px solid #ddcccc;
    margin-top: 10px;
}
.noticeFooter{
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    align-items: center;
}
.noticeDate{
    color:rgb(121, 109, 109);
}

.mybtn{
    padding:5px;
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: normal;
}