.staffHeader{
    background: rgb(9,9,121);
    background: linear-gradient(90deg, rgba(9,9,121,1) 0%, rgba(149,70,222,1) 32%, rgba(222,177,25,1) 100%, rgba(0,212,255,1) 100%);
    text-align: center;
    color:white;
    font-size: 18px;
    margin-top: 40px;
}
.staffData{
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-shadow: 0px 1px 11px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 1px 11px 2px rgba(0,0,0,0.75);
    box-shadow: 0px 1px 11px 2px rgba(0,0,0,0.75);
    height: 350px;
    justify-content: space-between;
    
}
.staffBodyData{
    text-align: center;
    background: #002147;
    display: flex;
    width: 100%;
    flex-direction: column;
    color: white;
}
.tech_photo{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-top: 10px;
}