.programs{
    background-color: #41929F;
    border-radius: 30px;
}
.icon{
    background: white;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-text{
    text-align: center;
}