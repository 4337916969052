a{
    text-decoration: none;
}
.slowtext{
    font-size: 25px;
}
.motiveRow{
    display: flex;
    align-items: end;
}
.ourTech{
    margin-left:20px;
}
.justify-center{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.graduationImg{
    position: relative;
    animation: myImgMove 4s infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
@keyframes myImgMove {
    0%   {top: 0px;}
    50%  {top: 20px;}
    100% {top: 0px;}
  }

  .ch-item {
	width: 100%;
	height: 100%;
	/*border-radius: 50%;*/
	position: relative;
	cursor: default;
	-webkit-perspective: 900px;
	-moz-perspective: 900px;
	-o-perspective: 900px;
	-ms-perspective: 900px;
	perspective: 900px;
}
.ch-info{
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-o-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
}
.img-cap {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 13px;
    background: rgba(0, 0, 0, .5);
    color: white;
    font-size: 20px;
}
.ch-info > div {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	/*border-radius: 50%;*/
	background-position: center center;
	-webkit-transition: all 0.4s linear;
	-moz-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	-ms-transition: all 0.4s linear;
	transition: all 0.4s linear;
	-webkit-transform-origin: 50% 0%;
	-moz-transform-origin: 50% 0%;
	-o-transform-origin: 50% 0%;
	-ms-transform-origin: 50% 0%;
	transform-origin: 50% 0%;
}

   

.civil-img { 
     /*img size: 250*250 */
        background-image: url("../../../public/techPhoto/hsc.jpg");
		background-repeat: no-repeat;
		background-size: cover;
	}
.cmt-img { 
        background-image: url("../../../public/techPhoto/degree.jpg");
		background-repeat: no-repeat;
		background-size: cover;
	}
.et-img { 
        background-image: url("../../../public/techPhoto/honours.png");
		background-repeat: no-repeat;
		background-size: cover;
	}

.ch-grid {
	margin: 20px 0 0 0;
	padding: 0;
	list-style: none;
	display: block;
	text-align: center;
	width: 100%;
}

.ch-grid:after,
.ch-item:before {
	content: '';
    display: table;
}

.ch-grid:after {
	clear: both;
}

.ch-grid li {
	width: 220px;
	height: 220px;
	display: inline-block;
	margin: 20px;
}


.ch-info .ch-info-back {
	-webkit-transform: translate3d(0,0,-220px) rotate3d(1,0,0,90deg);
	-moz-transform: translate3d(0,0,-220px) rotate3d(1,0,0,90deg);
	-o-transform: translate3d(0,0,-220px) rotate3d(1,0,0,90deg);
	-ms-transform: translate3d(0,0,-220px) rotate3d(1,0,0,90deg);
	transform: translate3d(0,0,-220px) rotate3d(1,0,0,90deg);
	background: #000;
	opacity: 0;
}




.ch-info h3 {
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 24px;
	margin: 0 15px;
	padding: 60px 0 0 0;
	height: 110px;
	font-family: 'Open Sans', Arial, sans-serif;
	text-shadow: 
		0 0 1px #fff, 
		0 1px 2px rgba(0,0,0,0.3);
}

.ch-info p {
	color: #fff;
	padding: 10px 5px;
	font-style: italic;
	margin: 0 30px;
	font-size: 12px;
	border-top: 1px solid rgba(255,255,255,0.5);
}

.ch-info p a {
	display: block;
	color: #fff;
	color: rgba(255,255,255,0.7);
	font-style: normal;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 9px;
	letter-spacing: 1px;
	padding-top: 4px;
	font-family: 'Open Sans', Arial, sans-serif;
}

.ch-info p a:hover {
	color: #fff222;
	color: rgba(255,242,34, 0.8);
}

.ch-item:hover .ch-info-front {
	-webkit-transform: translate3d(0,280px,0) rotate3d(1,0,0,-90deg);
	-moz-transform: translate3d(0,280px,0) rotate3d(1,0,0,-90deg);
	-o-transform: translate3d(0,280px,0) rotate3d(1,0,0,-90deg);
	-ms-transform: translate3d(0,280px,0) rotate3d(1,0,0,-90deg);
	transform: translate3d(0,280px,0) rotate3d(1,0,0,-90deg);
	opacity: 0;
}

.ch-item:hover .ch-info-back {
	-webkit-transform: rotate3d(1,0,0,0deg);
	-moz-transform: rotate3d(1,0,0,0deg);
	-o-transform: rotate3d(1,0,0,0deg);
	-ms-transform: rotate3d(1,0,0,0deg);
	transform: rotate3d(1,0,0,0deg);
	opacity: 1;
}
.w-title{
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 18px;
	padding-bottom: 10px;
}
.my-box {
	/* Required properties */
	position: relative;
	background: white;
	height: 260px;
	
	padding:15px;
	text-align: justify;
  }
  
  .my-box:before {
	content: "";
	z-index: -1;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: linear-gradient(to top left, #ff3d00 0%, #0400ff 100% );
	transform: translate3d(0px, 20px, 0) scale(0.95);
	filter: blur(20px);
	opacity: var(0.7);
	transition: opacity 0.3s;
  }
  
  .my-box::after {
	content: "";
	z-index: -1;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: inherit;
  }
  
 .first-plase{
	background: linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.7)), url('../../../public/com-img/success.png') center center/cover;
	height:170px;
}
  
 .guide-teacher{
	background: linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.7)), url('../../../public/com-img/guide-teacher.png') center center/cover;
	height:170px;	
}
 .class{
	background: linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.7)), url('../../../public/com-img/class.png') center center/cover;
	height:170px;	
}
 .res{
	background: linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.7)), url('../../../public/com-img/res.png') center center/cover;
	height:170px;	
}
 .wifi{
	background: linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.7)), url('../../../public/com-img/wifi.png') center center/cover;
	height:170px;	
}
 .co-cur{
	background: linear-gradient(rgba(255,255,255,0.7),rgba(255,255,255,0.7)), url('../../../public/com-img/co-cur.png') center center/cover;
	height:170px;	
}
 .no-smoke{
	background: linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.8)), url('../../../public/com-img/no-smokepng.png') center center/cover;
	height:170px;	
	
}
 .teacher{
	background: linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.8)), url('../../../public/com-img/teacher.png') center center/cover;
	height:170px;	
}