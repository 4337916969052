.carousel{
    box-shadow: 0px 3px 12px 3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 3px 12px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 12px 3px rgba(0,0,0,0.75);
}
.carouselImg{
    height: 450px;
    display:block;
}
.carouselCaption{
    background: rgba(0, 0, 0, 0.5);
    font-size: 19px;
    color: yellow;
}
.protHeader{
    font-size: 1.5rem;
    font-weight: bolder;
    color:rgb(17, 17, 78);
    position: relative;
    margin-bottom: 30px;
    text-align: center;
}
.protHeader::before{
    content: '';
    height: 2px;
    width: 40px;
    background-color: #b7b331;
    position: absolute;
    bottom: -10px;
    left: 47%;
    
}
.carouselImg{
    width: 100%;
    height: 450px;
}