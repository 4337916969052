.bani{
    box-shadow: 0px 3px 12px 3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 3px 12px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 12px 3px rgba(0,0,0,0.75);
    border-top: 4px solid;
    padding: 40px;
}

.baniHead{
    text-align: center;
    background-color: rgb(39 37 78);
    padding: 10px;
    color: wheat;
}

.baniPhoto{
    width: 170px;
    height:180px;
}
.baniText{
    text-align: justify;
    line-height: 35px;
}
.imagesSection{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.designation{
    margin-left: 40px;
    line-height: 45px;
}
.bani_img{
    width: 170px;
    border: 3px solid #ebdada;
}
.photoSection{
    box-shadow: 0px 3px 12px 3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 3px 12px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 12px 3px rgba(0,0,0,0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.photoSection img{
    width: 190px;
    height: 232px;
    padding: 14px 0px 6px 0px;
}
.baniSection{
    box-shadow: 0px 3px 12px 3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 3px 12px 3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 12px 3px rgba(0,0,0,0.75);
    padding:20px;
    position: relative;
    height: 290px;
}
.baniSection .mybtn{
    float: right;
    position: absolute;
    bottom: 10px;
    right:20px;
}
.principal_speach{
    text-align: justify;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;

}