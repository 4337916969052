
.bg-blueLight{
    background-color: #272953;
}


.navBar{
    display: flex;
    justify-content: space-between;
}
.navBar a{
    text-decoration: none;
    color:white;
    padding: 10px;
}
.form-title{
    padding: 20px;
    border-bottom: 1px solid rgb(44, 51, 119);
    margin-bottom: 20px;
    text-align: center;
}