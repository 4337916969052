.mainDiv{
    display: flex;
    justify-content: center;
    color: white;
}

.input-color{
    background: transparent;
    color: white;
}
.upload_file{
    width: 100px;
    margin:10px;
}