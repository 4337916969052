
.loadClass{
    position: fixed;
    z-index: 999999;
    background-color: rgba(252, 250, 250, 1);
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
}