.admission_div{
    background-image: linear-gradient(to right bottom, #00f680, #00efbc, #00e4e7, #00d6fa, #52c5f7, #69c4f4, #7ac4f1, #89c3ed, #77d2f7, #64e0fc, #59eef9, #5ffbf1);
    color:white;
}
.add_list ul{
    list-style: none;
    padding: 0;
    text-align: left;
}
.add_list ul li{
    padding-top:20px;
}
.card{
    border-top: 4px solid #252576;
}