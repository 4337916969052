.count_up{
    min-height: 300px;
    background-color: #161E2B;
    display: flex;
    align-items: center;
    justify-content: center;
}
.countDiv{
    height: 200px;
    width: 100%;
    color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    -webkit-box-shadow: -1px 0px 11px 0px rgba(250,250,250,1);
    -moz-box-shadow: -1px 0px 11px 0px rgba(250,250,250,1);
    box-shadow: -1px 0px 11px 0px rgba(250,250,250,1);
}
.countIcon{
    font-size: 55px;
}
.countNumber{
    font-size: 55px;
}
.countText{
    color:#d5d741;
}

@media screen and (max-width: 500px) {
    .countDiv {
      margin:30px 0px;
    }
  }