.footer{
    background-color: #282134;
    color: white;
}
.footer a{
    color: white;
    font-family: "Open Sans", sans-serif;
} 
.logo{
    width: 150px;
}
.footerCol p{
    color:white;
    font-family: "Open Sans", sans-serif;
}
.copyright{
    background-color: #4e465e;
    padding: 20px;
    margin-top: 10px;
    text-align: center;
}

@media screen and (max-width: 600px){
    .footerCol{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        border: 1px solid white;
        border-radius: 20px;
        background: #3c373f;
        padding: 10px;
    }
    
}
