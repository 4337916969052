.shortCourseContainer{
    text-align: center;
    margin-top: 20px;
}

.shortCourseCol{
    background: #ded9eb;
    padding: 21px;
}
.courseImg{
    height: 200px;
}
.courseText{
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}