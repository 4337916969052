.bodyLogo{
    width: 100px;
    padding: 0;
    margin: 0;
}
.navitem a {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    padding: 17px 0px;
  }
.navlink::after{
    content: '';
    height: 3px;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    transition: 0.5s;
    width: 0px;
    bottom: 0px;
    
}
.navlink:hover::after{
    left: 0%;
    right: 0%;
    width: 100%;
}



.logo{
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 25px;
}
.logo img{
    width: 40px;
    height:40px;
}
.navbars{
    flex:7;
    display: flex;
    align-items: center;
    justify-content: flex-end;  
}
.bar{
    
    display: flex;
    opacity: 0;
    align-items: center;
   
}
.bar-item{
    width:25px;
    height: 3px;
    background-color: white;
    position: relative;
    z-index: 1001;
   
}
.bar-item::after{
    content: '';
    width: inherit;
    height: inherit;
    background-color: white;
    position: absolute;
    transform: translateY(7px);
    transition: 0.5s;
}
.bar-item::before{
    content: '';
    width: inherit;
    height: inherit;
    background-color: white;
    position: absolute;
    transform: translateY(-7px);
    transition: 0.5s;
}
.checkbox{
    width: 25px;
    height: 40px;
    cursor: pointer;
    background-color: inherit;
    position: absolute;
    opacity: 0;
    right: 12px;
    z-index: 99999;
}
#checkbox:checked ~ .bar .bar-item{
    background-color: transparent;
}
#checkbox:checked ~ .bar .bar-item::after{
    transform: rotate(45deg);
}
#checkbox:checked ~ .bar .bar-item::before{
    transform: rotate(-45deg);
}


header{
    background-image: url("../../../public/college_photo.jpg");
    width: 100%;
    height: 100vh;    
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    display: flex;
}
.overlay{
    background: rgb(0,0,3);
background: linear-gradient(180deg, rgba(0,0,3,0.65) 0%,
 rgba(0,0,0,0.8) 100%);

    width: 100%;
    height: 100vh;
    z-index: 100;
    position: absolute;
}
.view{
    z-index: 106;
    position: absolute;
    width: 100%;
    height: 100vh;
}
#navId{
    box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.75);
}
.navbar{
    display: flex;
    padding: 0;
    margin: 0;
    position: relative;
}
.container-fluid{
    display: flex;
    padding:12px;
    transition: 0.5s;
}
.wav{
    z-index: 110;
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    top:0px;
}
.view{
    color: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 120;
}
.about_us_btn{
    padding:10px 20px;
    text-decoration: none;
    cursor:pointer;
    border: 2px solid white;
    color:white;
    background: transparent;
    transition: 0.8s;
}
.about_us_btn:hover{
    background: yellow;
    color:black;
    border:none;
}
.add{
    color:yellow;
}

.add
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #050505 0%,
    #b2ee0d 29%,
    #ff1361 67%,
    #03044d 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;

}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.collapse{
    position: absolute;
    z-index: 9999;
}
.navlink{
    color: white;
    transition: 0.2s;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    
}
.navitem{
    transition: 0.2s;
    padding: 0px 15px;
    list-style: none;
    position: relative;

}

.navitem:hover .dorpdown{
    transform: translateY(-1px);
    opacity: 1;
}
.dorpdown{
    position: absolute;
    transform: translateY(20px);
    transition: 0.5s;
    opacity: 0;
}
.dorpdown a{
    text-decoration: none;
    position: absolute;
    width:200px;
    background-color: white;
    padding:10px;
    display: block;
    cursor: pointer;
}
.dorpdown a:hover{
   background-color: rgb(144, 195, 241);
   color:black;
}
.res-logo{
    display:none;
}
.scroll-active{
    background-color:#27254e;
    padding:5px;
}

.navitem:hover .dropdown{
    visibility: visible;
    opacity: 1;
    transform: translateY(11px);
}

.dropdown{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 200px;
    background-color: #f8f8fc;
    transform: translateY(40px);
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
}

.dropdown a{
    color:black;
    padding:10px;
}

.dropdown a:hover{
    background-color: rgb(214, 221, 221);
    font-weight: bolder;
}
@media screen and (max-width:1000px){
    .dropdown{
        flex:initial;
        position: initial;
        transform: initial;
        width: 100%;
        display: none;
    }

    .navitem:hover .dropdown{
        transform: translateY(0px);
        
        display: flex;
        flex-direction: column;
    }

    .bar{
        opacity: 1;
    }

    .res-logo{
        visibility: visible;
        display: flex;
        transform: translate(25px, 15px);
        height: 50px;
        position: absolute;
        top:0;
        left: 0;
    }
    .res-logo p{
        color:white;
        text-transform: uppercase;
        transform: translateX(10px);
    }
    .res-logo img{
        opacity: 1;
        width: 50px;
    }
  
    nav{
        position: absolute;
        top: 100px;
        width: 100%;
    }
    .navbars{
        background-color: #27254e;
        position: absolute;
        top:0px;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: row-reverse;
        align-items:center;
        transform: translateX(100%);
        transition: 0.5s;
    }
    .navbar{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 20px;
        
    }
    .navitem{
        padding:10px;
        width: 100%;
    }
    .checkbox:checked ~ .navbars{
        transform: translateX(0);
    }
}